<template>
  <div class="user-edit px-3">
    <div class="d-flex align-items-center mt-2 mb-2">
      <button-back :link="{ name: 'users' }"></button-back>
      <h4 class="mr-auto mb-0">
        {{ type == "create" ? "Tạo" : "Sửa" }} tài khoản
      </h4>
      <b-button variant="success" size="sm" @click="save">Lưu</b-button>
    </div>

    <b-form-select :disabled="type == 'edit'" v-model="accType" class="mb-3">
      <b-form-select-option :value="null"
        >Chọn loại tài khoản</b-form-select-option
      >
      <b-form-select-option value="agent">Đại lý</b-form-select-option>
      <b-form-select-option value="user">Người dùng</b-form-select-option>
    </b-form-select>

    <div v-if="accType" class="step2">
      <b-input-group class="mb-2" size="sm" prepend="Tên đăng nhập">
        <b-form-input
          :disabled="type == 'edit'"
          type="text"
          v-model="username"
        ></b-form-input>
      </b-input-group>

      <b-input-group class="mb-2" size="sm" prepend="Mật khẩu">
        <b-form-input type="password" v-model="pass"></b-form-input>
      </b-input-group>

      <b-input-group
        size="sm"
        prepend="Ghi chú"
        label-for="u-note"
        class="mb-3 text-left_ noteg_"
      >
        <b-form-input id="u-note" v-model="note"></b-form-input>
      </b-input-group>

      <b-form-group :description="priceNote" class="mb-2 text-left">
        <b-input-group size="sm" prepend="Giá thuê" append="000">
          <b-form-input
            type="number"
            class="text-right"
            v-model="price"
          ></b-form-input>
        </b-input-group>
      </b-form-group>

      <b-form-group
        v-if="this.accType == 'user'"
        label="Gia hạn thêm"
        label-for="extend"
        class="text-left noteg"
        :description="'Sử dụng đến ngày ' + calcExpiredDate()"
      >
        <b-form-select id="extend" v-model="month">
          <b-form-select-option v-if="type == 'edit'" :value="0"
            >Không gia hạn</b-form-select-option
          >
          <b-form-select-option v-if="type == 'create'" value="7d"
            >Dùng thử 7 ngày</b-form-select-option
          >
          <b-form-select-option :value="1">1 tháng</b-form-select-option>
          <b-form-select-option :value="3">3 tháng (tặng 1 tháng)</b-form-select-option>
          <b-form-select-option :value="6"
            >6 tháng (tặng 2 tháng)</b-form-select-option
          >
          <b-form-select-option :value="12"
            >12 tháng (tặng 5 tháng)</b-form-select-option
          >
        </b-form-select>
      </b-form-group>
    </div>
    <b-button block variant="success" size="sm" @click="save">Lưu</b-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import StatsCard from '@/components/StatsCard';
import { Utilities } from "@/utilities";
import moment from "moment";

export default {
  data() {
    return {
      type: "create",
      bonus: { "6": 2, "12": 6 },
      accType: null,
      username: "",
      pass: "",
      note: "",
      price: "",
      month: 0,
      expires_date: ""
    };
  },
  watch: {
    accType: function(newVal) {
      this.price = newVal == "user" ? 1000 : 600;
    }
  },
  computed: {
    ...mapGetters([
      "currentUser" //this.currentUser
    ]),
    priceNote: function() {
      if (!this.accType) {
        return "";
      }

      let price = this.price * 1000;

      return `Giá thuê ${this.formatMoney(price)} / tháng`;
    }
  },
  mounted() {
    this.type = this.$route.params.uid ? "edit" : "create";
    this.month = this.type == "create" ? "7d" : 0;
    // this.price = !this.$route.params.uid ? this.currentUser.price/1000 : "";

    if (this.$route.params.type) {
      this.accType = this.$route.params.type;
    }
  },
  methods: {
    async tryFetchUserDetail() {
      if (!this.$route.params.uid) return;

      let res = await this.$http.get("user/" + this.$route.params.uid);
      if (res.data.code == 0) {
        this.accType = res.data.data.role;
        this.username = res.data.data.name;
        this.note = res.data.data.note ? res.data.data.note : "";
        this.price = res.data.data.price / 1000;
        this.expires_date = res.data.data.expired_time;
      }
    },
    formatMoney(money) {
      return money ? Utilities.thousandFormat(money) : 0;
    },
    calcExpiredDate() {
      let p = this.month;

      let newExpired = null;

      if (this.type == "create") {
        if (p == "7d") {
          newExpired = moment().add(7, "days");
        }

        if (p == "30d") {
          newExpired = moment().add(30, "days");
        }

        if (p > 0) {
          newExpired = moment().add(p, "M");
        }
      } else {
        newExpired = moment(this.expires_date, "YYYY-MM-DD").add(p, "M");
      }

      let bonus = this.bonus[p + ""];
      if (bonus) {
        newExpired.add(bonus, "M");
      }

      return newExpired.format("DD/MM/YYYY");
    },
    async save() {
      let url =
        this.type == "create" ? "user" : "user/" + this.$route.params.uid;
      let request = this.type == "create" ? this.$http.post : this.$http.put;
      let data = { price: this.price * 1000 };
      if (this.note) data.note = this.note;
      if (this.pass) data.password = this.pass;
      if (this.type == "create" && this.accType) data.role = this.accType;
      if (this.username) data.username = this.username;
      data.month = this.month;

      try {
        let res = await request(url, data);
        if (res.data.code == 0) {
          this.makeToast(
            "Thành công",
            "Tạo tài khoản thành công",
            "success",
            true
          );
          this.$router.push({ name: "users" });
          return;
        }
        this.makeToast("Lỗi", res.data.msg, "danger", true);
      } catch (err) {
        if (err.response && err.response.status == 422) {
          if (err.response.data.errors) {
            let errors = err.response.data.errors;
            for (let err in errors) {
              this.makeToast("Lỗi", errors[err].join("<br/>"), "danger", true);
            }
          }
        }
      }
    },
    makeToast(title, content, variant, append = false) {
      this.$bvToast.toast(content, {
        title: title,
        autoHideDelay: 3000,
        appendToast: append,
        variant: variant,
        solid: true,
        toaster: "b-toaster-bottom-right mb-5"
      });
    }
  },
  created() {
    this.tryFetchUserDetail();
  }
};
</script>

<style>
.noteg label {
  margin-bottom: 0;
}
.user-edit .input-group-text {
  width: 120px;
}
</style>
